
.custom-header-icon .ag-header-cell-label {
  display: flex;
  align-items: center;
  white-space: nowrap; 
  gap: 10px;
  /* justify-content: space-between; */
}
.custom-header-icon .ag-header-cell-label::after {
  content: '\25B3';  
  margin-left: 2px; 
  font-family: Arial, sans-serif; 
  font-weight: bold;
  font-size: 12px;
  color: #BFBFBF;
}

.highlighted-row{
  background-color: orange !important;
  
}